import React from 'react';
import { connect } from 'react-redux';
import { userActions, SupportContactArea } from '../../../../components';
import { utilService, history } from '../../../../_helpers';
import '../UserLoginPage.scss';
import { tenantService } from '../../../Tenant';
import withLiveUpdateHook from '../../../Proof/LiveUpdateWrapper';
import { withRouter } from 'react-router-dom';
import localStorage from 'local-storage';
import { isCompactNav } from '../../../../_helpers/navbarUtil';
import PulseLoader from '../../../_shared/Loaders/PulseLoader';

class WppOpenLanding extends React.Component {
    constructor(props) {
        super(props);
        const subdomain = window.location.host.split('.')[0];
        const username = (props.location.username == undefined)? "": props.location.username;
        this.state = {
            username: username,
            password: '',
            submitted: false,
            subdomain: subdomain,
            loginNoMatch: true,
            loginErrorMessage: "",
            redirectMessage: '',
            tenantExist: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.tryLogin.bind(this);
    }

    async componentDidMount() {
          
        document.title = 'Brandshare Collaboration - Login';
        
        if (isCompactNav() !== true){
            history.replace({ pathname: 'hosted' });
            return;
        }

        this.props.liveUpdateHook.setConnectionStatus("Disconnected");
        const { data } = this.props.location;
        if (data && data.redirectMessage) {
            this.setState({ redirectMessage: data.redirectMessage });
        }
        const subdomain = window.location.hostname.split('.')[0];
        var tenantNotExist = await tenantService.isSubdomainUnique(subdomain);
        if (tenantNotExist) {
            this.setState({ redirectMessage: "Tenant isn't registered ! ", tenantExist: false });
        } else {
            const { authentication } = this.props;
            
            if (authentication && Object.getOwnPropertyNames(authentication).length !== 0) {
                history.replace({ pathname: '/' });
            }
        }

        this.tryLogin();
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            submitted: false,
            loginNoMatch: false,
            redirectMessage: ''
        });
    }

    getImageSource() {
        var preference = localStorage.get('preference-theme') || 'light';        
        return preference === 'dark'?  '../../assets/Images/brand_logo_all_white.png' : '../../assets/Images/brand_logo.png';
    }

    async tryLogin() {
        // await utilService.setStateAsync.bind(this)({
        //     submitted: true
        // });
        
        // var userDetails = this.props.location.osContext?.userDetails;
        var userDetails = this.props.osContext?.userDetails;

        const subdomain = window.location.host.split('.')[0];
        if (userDetails) {
            var responseAuth = await this.props.dispatch(
                userActions.wppLogin(userDetails, subdomain)
            );
            
            if (responseAuth && !responseAuth.success) {
                await utilService.setStateAsync.bind(this)({
                    loginNoMatch: true,
                    loginErrorMessage: responseAuth.message || "Incorrect email or password"
                });
            } else {
                var { from } = this.props?.location?.redirect || {}
                from = from?.pathname ? from : {
                    pathname: '/' 
                };
                history.push({ ...from, hasBackRoute: false });
            }
        }
        
    }
    
    render() {
        const {
            username,
            password,
            submitted,
            loginNoMatch,
            loginErrorMessage,
            redirectMessage,
            tenantExist
        } = this.state;
        return (
           <>          
            <div className="mrnda-main-content padding-2x">
                <div className="mrnda-flex-center-wrapper">
               
               
                {   !loginErrorMessage &&<PulseLoader backgroundColor={'var(--primary-text-color)'}/>  }
                
                    {
                        loginErrorMessage && 
                        <div className="sign-in-box-wrapper">
                        <div className="tenant-logo margin-2x--bottom typography--center">
                            <div>
                                {/* <i style={{ color: '#fc275c' }} className="fab fa-mix fa-7x"></i> */}
                                <img src={this.getImageSource()}></img>
                            </div>
                        </div>
                        <div className="sign-in-box mrnda-card padding-3x margin-4x--bottom">
                            <div className="tile-message">
                                {loginNoMatch && (
                                    <div style={{marginBottom: '0px'}} className="typography--center typography--caution margin-2x--bottom">
                                        {loginErrorMessage}
                                    </div>
                                )}
                                {redirectMessage && redirectMessage.trim().length && (
                                    <div className="typography--center typography--caution margin-2x--bottom">
                                        {redirectMessage}
                                    </div>
                                )}
                            
                            </div>
                        </div>
                        <SupportContactArea />
                    </div>
                    }
                </div>
            </div>
            
           </>
        );
    }
}

function mapStateToProps(state) {    
    const { authentication } = state;
    return {
        authentication: authentication.user ? authentication.user.authData : {}
    };
}

const withLiveUpdate = withLiveUpdateHook(WppOpenLanding);

const connectedUserLoginPage = connect(mapStateToProps)(withRouter(withLiveUpdate));
export { connectedUserLoginPage as WppOpenLanding };
