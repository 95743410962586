import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import NavBar from '../Nav';
import {
    UserLoginPage,
    TenantDashboard,
    TenantHomePage,
    PrivateRoute,
    ProofViewer,
    ProofEditRequest,
    SettingsPage,
    FileDownloadRequest,
    authorizationConstants as authConst,
    ProofSummary,
    UserRegister,
    UserRegisterRequest,
    UserForgotPassword,
    UserResetPassword,
    AnnotationReport
} from '../../../components';
import { ProofVideoTranscription } from '../../Proof/VideoTranscription/ProofVideoTranscription';
import { ProofSummaryRequest } from '../../Proof';
import IdleTimer from 'react-idle-timer';
import { history } from '../../../_helpers';
import { userActions, userService } from '../../User';
import { tenantService } from '../../Tenant';
import { ProofDocumentEditor, RequestProofDocumentEditor } from '../../Proof/DocumentAnnotation/ProofDocumentEditor';
import { ProofVideoEditor, RequestProofVideoEditor } from '../../Proof/VideoAnnotation/ProofVideoEditor';
import { ProofDocumentCompare } from '../../Proof/DocumentCompare/ProofDocumentCompare';
import { ProofVideoCompare } from '../../Proof/VideoCompare/ProofVideoCompare';
import { UserLandingPage } from '../../User/LoginRegistration/UserLandingPage';
import { OktaAuthLanding } from '../../User/LoginRegistration/OktaAuthLanding';
import { ProofShareEditRequest } from '../../Proof/ProofShareEditRequest';
import { ProofShareEditor } from '../../Proof/ProofShareEditor';
import SupportRequest from '../../Support/SupportRequest';
import ThirdPartyLanding from '../ThirdPartyLanding';
import { FooterComponent } from '../FooterComponent';
import { isCompactNav } from '../../../_helpers/navbarUtil';


function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert        
    };
}

class DefaultTenantPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            browserSessionTimeout: 120
        };
    }

    async componentDidMount() {
        let tenant = await tenantService.getCurrentTenantObject();

        var isActive = await tenantService.isExists(tenant.id);

        if(isActive === false) {
            userService.logout();
        }

        this.setState({
            browserSessionTimeout: tenant?.browserSessionTimeout || 120
        });
        console.log('Setting user idle timeout:' , tenant?.browserSessionTimeout || 120)
    }

    handleOnAction(event) {
      
    }

    handleOnActive(event) {
        console.log('user is active', event);
        console.log('time remaining', this.idleTimer.getRemainingTime());
    }

    handleOnIdle(event) {
        console.log('user is idle, loggin out', event);
        console.log('last active', this.idleTimer.getLastActiveTime());
        this.props.dispatch(userActions.logout());
        history.push('/landing');
    }

    render() {
        return (
            <>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    timeout={1000 * 60 * this.state.browserSessionTimeout}
                    onActive={this.handleOnActive.bind(this)}
                    onIdle={this.handleOnIdle.bind(this)}
                    onAction={this.handleOnAction.bind(this)}
                    debounce={250}
                />
                
                { isCompactNav() === true?  <FooterComponent/> : <NavBar />}
                <div className='route-container'>
                <Switch>
                    <PrivateRoute
                        exact
                        path="/dashboard/:viewtype/:nodeid"
                        component={TenantDashboard}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{}}
                        bypassAuthorization={true}
                    />
                    <PrivateRoute
                        exact
                        path="/dashboard/:viewtype/"
                        component={TenantDashboard}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{}}
                        bypassAuthorization={true}
                    />
                    <PrivateRoute
                        exact
                        path="/"
                        component={TenantDashboard}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{}}
                        bypassAuthorization={true}
                    />
                    <PrivateRoute
                        exact
                        path="/home"
                        component={TenantDashboard}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{}}
                        bypassAuthorization={true}
                    />
                    <PrivateRoute
                        exact
                        path="/dashboard/lists"
                        component={TenantDashboard}
                        loginPath="login"
                        landingPath="/landing"
                        permissionKey={{}}
                        bypassAuthorization={true}
                    />
                    <PrivateRoute
                        exact
                        path="/proof/:proofid/:versionid"
                        component={ProofViewer}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.Dashboard.KEY,
                            action: authConst.Dashboard.ACTION_DASHBOARD_PROOF_VIEW
                        }}
                    />
                    <PrivateRoute
                        exact
                        path="/proof/video/:proofid/:versionid"
                        component={ProofVideoEditor}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.ProofViewer.KEY,
                            action: authConst.ProofViewer.ACTION_PROOF_VIEWER_EDIT
                        }}
                    />
                    <PrivateRoute
                        exact
                        path="/proofrequest/video/:proofid/:versionid"
                        component={RequestProofVideoEditor}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.ProofViewer.KEY,
                            action: authConst.ProofViewer.ACTION_PROOF_VIEWER_EDIT
                        }}
                    />
                    <PrivateRoute
                        exact
                        path="/proof/document/:proofid/:versionid"
                        component={ProofDocumentEditor}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.ProofViewer.KEY,
                            action: authConst.ProofViewer.ACTION_PROOF_VIEWER_EDIT
                        }}
                        
                    />
                    <PrivateRoute
                        exact
                        path="/proofrequest/document/:proofid/:versionid"
                        component={RequestProofDocumentEditor}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.ProofViewer.KEY,
                            action: authConst.ProofViewer.ACTION_PROOF_VIEWER_EDIT
                        }}
                    />
                    <PrivateRoute
                        exact
                        path="/share/proof/:proofid/:versionid"
                        component={ProofShareEditor}
                        loginPath="/login"
                        landingPath="/landing" 
                        bypassAuthorization={true}                       
                    />
                    <PrivateRoute
                        exact
                        path="/proofvideocompare"
                        component={ProofVideoCompare}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.ProofViewer.KEY,
                            action: authConst.ProofViewer.ACTION_PROOF_VIEWER_COMPARE
                        }}
                    />
                    {/* <PrivateRoute
                        exact
                        path="/proof/video/transcription/:proofid/:versionid"
                        component={ProofVideoTranscription}
                        loginPath="/login"
                        permissionKey={{
                            module: authConst.ProofViewer.KEY,
                            action: authConst.ProofViewer.ACTION_PROOF_VIEWER_EDIT
                        }}
                    /> */}

                    {/* 
                    
                    NOTE: Leadtools remove
                        <PrivateRoute
                        exact
                        path="/proof/edit/:proofid/:versionid"
                        component={ProofEditor}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.ProofViewer.KEY,
                            action: authConst.ProofViewer.ACTION_PROOF_VIEWER_EDIT
                        }}
                    /> */}
                    {/* NOTE: Leadtools remove   */}
                    {/* <PrivateRoute
                        exact
                        path="/proofcompare"
                        component={ProofCompare}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.ProofViewer.KEY,
                            action: authConst.ProofViewer.ACTION_PROOF_VIEWER_COMPARE
                        }}
                    /> */}
                    <PrivateRoute
                        exact
                        path="/settings"
                        component={SettingsPage}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{
                            module: authConst.Settings.KEY,
                            action: authConst.Settings.ACTION_SETTINGS_VIEW
                        }}
                    />
                   <Route
                        path="/share/filedownload/:linkToken"
                        component={FileDownloadRequest}
                    />
                    <Route
                        path="/request/proof/edit/:proofid/:versionid"
                        component={ProofEditRequest}
                    />
                    <Route
                        path="/request/proof/report/:proofid/:versionid"
                        component={ProofSummaryRequest}
                    />
                    {/* <PrivateRoute
                        exact
                        path="/proof/report/:proofid/:versionid"
                        component={ProofSummary}
                        loginPath="/login"
                        permissionKey={{}}
                        bypassAuthorization={true}
                    /> */}
                    <PrivateRoute
                        exact
                        path="/proof/annotationreport/:proofid/:versionid"
                        component={AnnotationReport}
                        loginPath="/login"
                        landingPath="/landing"
                        permissionKey={{}}
                        bypassAuthorization={true}
                    />

                    <Route
                        path="/proof/video/transcription/:proofid/:versionid"
                        component={ProofVideoTranscription}
                    />
                    <Route path="/createaccount" component={UserRegister} />
                    <Route path="/request/user/acceptinvite" component={UserRegisterRequest} />
                    <Route path="/forgotpassword" component={UserForgotPassword} />
                    <Route path="/resetpassword" component={UserResetPassword} />
                    <Route path="/login" component={UserLoginPage} />
                    <Route path="/landing" component={UserLandingPage} />
                    <Route path="/oktaauth" component={OktaAuthLanding} />
                    <Route
                        exact
                        path="/share/:linkToken"
                        component={FileDownloadRequest}
                    />
                    <Route
                        exact
                        path="/share/:linkToken/:targetParam"
                        component={FileDownloadRequest}
                    />
                    <Route
                        exact
                        path="/share/:edit/:linkToken/:targetParam"
                        component={FileDownloadRequest}
                    />

                    <Route
                        exact
                        path="/share/edit/:proofId/:versionId/:token"
                        component={ProofShareEditRequest}
                    />
                    
                    <Route
                        exact
                        path="/share/proof/editor/:proofId/:versionId"
                        component={ProofShareEditor}
                    />
                
                    <Route
                        path="/proofdocumentcompare"
                        component={ProofDocumentCompare}
                    />
                    
                    <Route
                        exact                        
                        path="/newsupportrequest"
                        component={SupportRequest}
                    />
                    <Route  
                        exact                      
                        path="/hosted"
                        component={ThirdPartyLanding}
                    />
                    
                </Switch>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps)(DefaultTenantPage);
