import React from 'react';
import { useTheme } from '../../App/Theme/ThemeContext';
import { colorUtilService } from '../../../_helpers';
import { NavPrimaryActions } from '../../App/NavPrimaryActions';
import NavSecondaryActions from '../../App/NavSecondaryActions';
import { isCompactNav, } from '../../../_helpers/navbarUtil';

const PreComponent = (props) => <NavPrimaryActions {...props} />;
const PostComponent = (props) => <NavSecondaryActions {...props} />;

const withPrimaryNav = (WrappedComponent, showRightActions = true, style = {}, primaryActionsStyle = {}, pageActionsStyle = {}  ,secondaryActionsStyle = {}, extraText = "") => {        
    const compactNavbar = isCompactNav();
    const { theme, themeColors } = useTheme();
    var color = 'test';

    return (props) => (
        <div
            style={{
                ...{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '60px',
                    minHeight: '60px',                    
                    backgroundColor: themeColors?  colorUtilService.darken(themeColors?.primaryBackgroundColor, 0.3): 'var(--primary-background-color)',
                    zIndex: '4',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    paddingRight: '20px',                    
                },
                ...style
            }}>
            <PreComponent extraText = {extraText} compactNavbar={compactNavbar} customStyles = {primaryActionsStyle}/>
            {WrappedComponent ? (
                <div style={{ flex: '1', ...pageActionsStyle}}>                                     
                    <WrappedComponent {...props} compactNavbar={compactNavbar} />
                </div>
            ) : (
                <div style={{ flex: '1' }}>
                </div>
            )}
            {showRightActions && <PostComponent compactNavbar={compactNavbar} customStyles = {secondaryActionsStyle} disableLogin = {props.disableLogin}/>}
        </div>
    );
};

export default withPrimaryNav;





const WrappedInsidePrimaryNav = ({WrappedComponent, showRightActions = true, style = {}, primaryActionsStyle = {}, pageActionsStyle = {}  ,secondaryActionsStyle = {}, extraText = "", ...props}) => {        
    const compactNavbar = isCompactNav();
    const { theme, themeColors } = useTheme();
    var color = 'test';

    return (
        <div
            style={{
                ...{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '60px',
                    minHeight: '60px',                    
                    backgroundColor: themeColors?  colorUtilService.darken(themeColors?.primaryBackgroundColor, 0.3): 'var(--primary-background-color)',
                    zIndex: '4',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    paddingRight: '20px',                    
                },
                ...style
            }}>
            <PreComponent extraText = {extraText} compactNavbar={compactNavbar} customStyles = {primaryActionsStyle}/>
            {WrappedComponent ? (
                <div style={{ flex: '1', ...pageActionsStyle}}>                                      
                    <WrappedComponent {...props} compactNavbar={compactNavbar} />
                </div>
            ) : (
                <div style={{ flex: '1' }}>
                </div>
            )}
            {showRightActions && <PostComponent compactNavbar={compactNavbar} customStyles = {secondaryActionsStyle}/>}
        </div>
    );
};

export { WrappedInsidePrimaryNav } ;