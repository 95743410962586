import React, { createContext, useContext, useEffect, useState } from 'react';
import { connectToParent } from 'penpal';

const OsContext = createContext(null);

export const OsContextProvider = ({ children }) => {
    const [osContext, setOsContext] = useState(null);
    const [isContextPresent, setIsContextPresent] = useState(false);

    useEffect(() => {
        onLoaded();
    }, []);

    const onContextReceived = async (osContext) => {
        setOsContext(osContext);
        setIsContextPresent(true);
    };

    const onLoaded = async () => {
        connectToParentContext();
    };

    const connectToParentContext = () => {
        const connection = connectToParent({
            parentOrigin: '*',
            methods: {
                receiveOsContext: (osContext) => {                   
                    onContextReceived(osContext);
                }
            },
            timeout: 1000
        });

        connection.promise.then(({ osApi }) => {
            osApi.getAccessToken().then((value) => {                
            });
        });

        connection.promise.catch((error) => {
            console.log('Penpal child context error:', error);
        });
    };

    return (
        <OsContext.Provider value={{ isContextPresent, osContext }}>{children}</OsContext.Provider>
    );
};

export const useOsContext = () => {
    const context = useContext(OsContext);
    if (context === null) {
        throw new Error('useOsContext must be used within an OsContextProvider');
    }
    return context;
};
