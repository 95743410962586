import React, { useState } from 'react';
import cx from 'classnames';
import { ConfirmationModal } from '../../_shared';

export function WPPOpenDomain(props) {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDefaultUpdateConfirm, setShowDefaultUpdateConfirm] = useState(false);

    const onDelete = () => {
        setShowDeleteConfirm(true);
    };

    const defaultDomainChange = (event) => {
        var { checked } = event.target;

        if (
            checked === true &&
            (props.config.roleId === '' ||
                props.config.roleId === undefined ||
                props.config.roleId === null)
        ) {
            setShowDefaultUpdateConfirm(true);
        } else {
            props.defaultDomainChange(checked);
        }
    };

    const handleDefaultDomainUpdate = () => {
        setShowDefaultUpdateConfirm(false);
        props.onEdit(props.config);
    };

    return (
        <>
            <tr>
                <td>
                    <div
                        className={cx({
                            'wpp-domain-cell': true,
                            'wpp-domain-cell-action': 'true'
                        })}>
                        <a
                            className="_s_DeleteTreeItems folder-node-delete"
                            title="Edit Status"
                            onClick={() => {
                                props.onEdit(props.config);
                            }}>
                            <i className="fas fa-pen" />
                        </a>

                        {props.config.isDefault === true ? (
                            <input
                                type="checkbox"
                                className="ml-2 mr-2 domain-name-default-check"
                                data-toggle="toggle"
                                data-on="Enabled"
                                data-off="Disabled"
                                name="enabled"
                                checked={props.config.isActive}
                                onChange={defaultDomainChange}
                            />
                        ) : (
                            <a
                                className="_s_DeleteTreeItems folder-node-delete"
                                style={{ marginLeft: '12px', color: 'red' }}
                                title="Delete"
                                onClick={onDelete}>
                                <i className="far fa-trash-alt" />
                            </a>
                        )}
                    </div>
                </td>
                <td>{props.config.name}</td>
                <td>{props.config.role}</td>
                <td>{props.config.groupNames}</td>
            </tr>

            {showDeleteConfirm && (
                <ConfirmationModal
                    show={showDeleteConfirm}
                    onHide={() => setShowDeleteConfirm(false)}
                    onPrimaryClick={() => {
                        props.handleDomainDelete(props.config);
                        setShowDeleteConfirm(false);
                    }}
                    onSecondaryClick={() => setShowDeleteConfirm(false)}
                    primaryButtonText="Ok"
                    secondaryButtonText="Cancel"
                    title={`Delete "${props.config?.name}"`}
                    bodyText="Are you sure you want to delete this ?"
                />
            )}

            {showDefaultUpdateConfirm && (
                <ConfirmationModal
                    show={showDefaultUpdateConfirm}
                    onHide={() => setShowDefaultUpdateConfirm(false)}
                    onPrimaryClick={() => {
                        handleDefaultDomainUpdate();
                    }}
                    onSecondaryClick={() => setShowDefaultUpdateConfirm(false)}
                    primaryButtonText="Select"
                    secondaryButtonText="Cancel"
                    title={`No role set in default domain`}
                    bodyText="A role needs to be selected to enable default domain"
                />
            )}
        </>
    );
}
