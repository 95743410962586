import React from 'react';
import { useOsContext } from '../../../Settings/WPPOpenLogin/OsContextProvider';

const withWppContext = (Component) => {
    return function WrappedComponent(props) {
        const wppOsContextHook = useOsContext();
        return <Component {...props} wppOsContextHook={wppOsContextHook} />;
    };
};

export default withWppContext;
