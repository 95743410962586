import React from 'react';

export function CheckboxInput({ header, title, ...props }) {
    return (
        <div className={'form-group'}>
            <label htmlFor="status">{header}</label>
            <label className="check-container display--block ml-1">
                <span className="display--block role-permission-section__body__check-label">
                    <strong>{title}</strong>
                </span>
                <input
                    type="checkbox"
                    className="ml-2 mr-2"
                    data-toggle="toggle"
                    data-on="Enabled"
                    data-off="Disabled"
                    name="enabled"
                    checked={props.enabled}
                    onChange={props.handleChange}
                />
                <span className="check-container__checkmark">
                    <i className="fas fa-check" />
                </span>
            </label>
            {props.validation && !props.validation.validated && (
                <div className="help-block" style= {{ paddingLeft: '.25rem'}}>{props.validation.message}</div>
            )}
        </div>
    );
}
