import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { userActions, SupportContactArea } from '../../../components';
import { utilService, history, config } from '../../../_helpers';
import './UserLoginPage.scss';
import { tenantService } from '../../Tenant';
import withLiveUpdateHook from '../../Proof/LiveUpdateWrapper';
import { withRouter } from 'react-router-dom';
import localStorage from 'local-storage';
import withNoAuthNav from '../../_shared/Nav/withNoAuthNav';
import withWppContext from './WPPOpen/withWppContext';
import { WppOpenLanding } from './WPPOpen/WppOpenLanding';
const test = false;
class UserLandingPage extends React.Component {
    constructor(props) {
        super(props);
        const subdomain = window.location.host.split('.')[0];
        const isLogoutReloading = props.location.isLogoutReloading == undefined? false : props.location.isLogoutReloading;
        const isOktaGatewayRedirection = (props.location.data == undefined || props.location.data.isOktaGatewayRedirection == undefined)? false : props.location.data.isOktaGatewayRedirection;
        const isOktaRedirection = (props.location.data == undefined || props.location.data.isOktaRedirection == undefined)? false : props.location.data.isOktaRedirection;

        this.state = {
            username: '',
            submitted: false,
            subdomain: subdomain,
            loginNoMatch: true,
            loginErrorMessage: "",
            redirectMessage: '',
            tenantExist: true,
            isEnable: false,
            isLogoutReloading: isLogoutReloading,
            isOktaGatewayRedirection: isOktaGatewayRedirection,
            isOktaRedirection: isOktaRedirection
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);         
    }

    componentDidUpdate(prevProps) {
        if (this.props.wppOsContextHook.isContextPresent === prevProps.wppOsContextHook.isContextPresent){
            return;
        }
        if (this.props.wppOsContextHook.isContextPresent === true) {
            //App loaded inside wpp open
            console.log('App loaded inside wpp open!');
            // var { from } = { from: { pathname: '/wppopenauth' } };
            // history.push({ ...from, hasBackRoute: false, redirect: { from: this.props?.location?.state?.from }, osContext: this.props.wppOsContextHook.osContext });
        }
    }
        
    async componentDidMount() {
        var tenantName = tenantService.getCurrentTenant();
        const { isContextPresent, osContext } = this.props.wppOsContextHook;
        var defaultProvider = await tenantService.checkDefaultProvider(tenantName);
        
        if (this.props.wppOsContextHook.isContextPresent === true) {
            return;
        }
        if (defaultProvider && defaultProvider.id == 1) {
            var { from } = {from: { pathname: '/login'}};
            history.push({ ...from, hasBackRoute: false, redirect: {from: this.props?.location?.state?.from} });
        } else if (this.state.isLogoutReloading == false  && (this.state.isOktaGatewayRedirection == true || (defaultProvider && defaultProvider.id == 3))) {
            var selfUrl =  window.location.origin;   
            var url = `${config.oktaGatewayLoginUrl}?ReturnUrl=${selfUrl}/__hash__/oktaauth`;
            window.location.href = url;
        }else if (this.state.isLogoutReloading == false  && (this.state.isOktaRedirection == true || (defaultProvider && defaultProvider.id == 4))) {
            var selfUrl =  window.location.origin;           
            var url = `${config.apiUrl}/auth/oktalogin?ReturnUrl=${selfUrl}/__hash__/oktaauth`;
            window.location.href = url;
        }
        else {
            document.title = 'Brandshare Collaboration - Login';
            this.props.liveUpdateHook.setConnectionStatus("Disconnected");
            const { data } = this.props.location;
            if (data && data.redirectMessage) {
                this.setState({ redirectMessage: data.redirectMessage });
            }
            const subdomain = window.location.hostname.split('.')[0];
            var tenantNotExist = await tenantService.isSubdomainUnique(subdomain);
            if (tenantNotExist) {
                this.setState({ redirectMessage: "Tenant isn't registered ! ", tenantExist: false });
            }     
            this.setState({ isEnable: true });
        }    
    }
    

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            submitted: false,
            loginNoMatch: false,
            redirectMessage: ''
        });
    }

    getImageSource() {
        var preference = localStorage.get('preference-theme') || 'light';        
        return preference === 'dark'?  '../../assets/Images/brand_logo_all_white.png' : '../../assets/Images/brand_logo.png';
    }

    async handleSubmit(e) {
        e.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true
        });
        const { username } = this.state;
        const subdomain = window.location.host.split('.')[0];
        if (username ) {
            var responseAuth = await this.props.dispatch(                
                userActions.checkProviderByEmail(username, subdomain)
            );
            if (responseAuth && !responseAuth.success) {
                await utilService.setStateAsync.bind(this)({
                    loginNoMatch: true,
                    loginErrorMessage: responseAuth.message || "Incorrect email"
                });  
            } else {
                if(responseAuth.authProvider == "OktaGateway"){      
                    var selfUrl =  window.location.origin;           
                    var url = `${config.oktaGatewayLoginUrl}?ReturnUrl=${selfUrl}/__hash__/oktaauth`;
                    window.location.href = url;
                }else if(responseAuth.authProvider == "Okta"){      
                    var selfUrl =  window.location.origin;           
                    var url = `${config.apiUrl}/auth/oktalogin?ReturnUrl=${selfUrl}/__hash__/oktaauth`;
                    window.location.href = url;
                }
                else{
                    var { from } = {from: { pathname: '/login' }};
                    history.push({ ...from, hasBackRoute: false, username: username, redirect: {from: this.props?.location?.state?.from} });
                }
            }
        }
    }

    render() {
        const {
            username,
            submitted,
            loginNoMatch,
            loginErrorMessage,
            redirectMessage,
            tenantExist,
            isEnable
        } = this.state;
        return (
            <>
            {                
                this.props.wppOsContextHook.isContextPresent === true ? <WppOpenLanding osContext = {this.props.wppOsContextHook.osContext} /> 
                : ((this.state.isEnable) == undefined || (this.state.isEnable) == false) ? null :
                <div className="mrnda-main-content padding-2x">
                    <div className="mrnda-flex-center-wrapper">
                        <div className="sign-in-box-wrapper">
                            <div className="tenant-logo margin-2x--bottom typography--center">
                                <div>
                                    <img src={this.getImageSource()}></img>
                                </div>
                            </div>
                            <div className="sign-in-box mrnda-card padding-3x margin-4x--bottom">
                                <h1 className="margin-3x--bottom text--center">Welcome</h1>
                                <div className="tile-message">
                                    {submitted && loginNoMatch && (
                                        <div className="typography--center typography--caution margin-2x--bottom">
                                            {loginErrorMessage}
                                        </div>
                                    )}
                                    {redirectMessage && redirectMessage.trim().length && (
                                        <div className="typography--center typography--caution margin-2x--bottom">
                                            {redirectMessage}
                                        </div>
                                    )}
                                    <div className={cx({ 'd-none': !tenantExist })}>
                                        <form className="" name="form" onSubmit={this.handleSubmit}>
                                            <div className="form-horizontal">
                                                <div className="form-horizontal">
                                                    <input
                                                        type="email"
                                                        id="inputEmail"
                                                        className="mrnda-input-text margin-2x--bottom"
                                                        name="username"
                                                        value={username}
                                                        onChange={this.handleChange}
                                                        placeholder="Email"
                                                        required
                                                        autoFocus=""
                                                    />
    
                                                    <div className="control-group">
                                                        <div className="controls">
                                                            <button className="mrnda-btn mrnda-btn--full margin-2x--bottom">
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>                                    
                                    </div>
                                </div>
                            </div>
                            <SupportContactArea />
                        </div>
                    </div>
                </div> 
            }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
    
}
const withWPPContext = withWppContext(UserLandingPage);
const withLiveUpdate = withLiveUpdateHook(withWPPContext);
const connectedUserLandingPage = withNoAuthNav(connect(mapStateToProps)(withRouter(withLiveUpdate)));
export { connectedUserLandingPage as UserLandingPage };
