import { func } from 'prop-types';
import { authHeader, handleResponse, config } from '../../_helpers';

export const userService = {
    login,
    logout,
    createAccount,
    getAll,
    getAllV2,
    getAllUsersInfo,
    getById,
    update,
    delete: _delete,
    getCurrentUser,
    getAllUserFilters,
    validatePassword,
    validateEmail,
    isEmailUnique,
    validateInvite,
    validatePasswordReset,
    registerAccount,
    forgotPasswordInvite,
    resetPassword,
    sendRegistrationInviteEmail,
    validateDomain,
    search,
    checkProviderByEmail,
    redirectToOktaLogin,
    getAllProviders,
    searchUsers,
    wppOpenLogin,
    registerAccountWPPOpen
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', mode: 'cors' },
        body: JSON.stringify({
            Email: username,
            Password: password
        })
    };
    return fetch(`${config.apiUrl}/auth/login`, requestOptions).then(handleResponse);
    // .then(user => {
    //     // store user details and jwt token in local storage to keep user logged in between page refreshes
    //     localStorage.setItem('user', JSON.stringify(user));
    //     console.log("35: Inside UserService", user);
    //     return user;
    // });
    //return user;
}

function checkProviderByEmail(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', mode: 'cors' },
        body: JSON.stringify({
            Email: username
        })
    };
    return fetch(`${config.apiUrl}/auth/checkProviderByEmail`, requestOptions).then(handleResponse);
}

function redirectToOktaLogin(autheToken){ 
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', mode: 'cors' },
        body: JSON.stringify({
            AutheToken: autheToken
        }) 
    };
    return fetch(`${config.apiUrl}/auth/redirectToOktaLogin`, requestOptions).then(handleResponse);    
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('tenant');
}

function validatePassword(password) {
    var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\W_]{8,}/;
    return !re.test(password);
}

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(email);
}

function validateDomain(domain) {
    var re = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
    return !re.test(domain);
}

function getAll(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj)
    };
    return fetch(`${config.apiUrl}/user/getusers`, requestOptions).then(handleResponse);
}

function searchUsers(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj)
    };
    return fetch(`${config.apiUrl}/user/searchUsers`, requestOptions).then(handleResponse);
}

function getAllV2(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj)
    };
    return fetch(`${config.apiUrl}/user/getusersV2`, requestOptions).then(handleResponse);
}

function getAllUsersInfo(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj)
    };
    return fetch(`${config.apiUrl}/user/getAllUsersInfo`, requestOptions).then(handleResponse);
}

function search(text) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
        // body: tenantId
    };

    return fetch(`${config.apiUrl}/user/searchusers/${text}`, requestOptions).then(handleResponse);
}

function getAllUserFilters() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
        // body: tenantId
    };

    return fetch(`${config.apiUrl}/user/alluserfilters`, requestOptions).then(handleResponse);
}
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function createAccount(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/user/createaccount`, requestOptions).then(handleResponse);
}

function registerAccount(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };

    return fetch(`${config.apiUrl}/user/registeraccount`, requestOptions).then(handleResponse);
}

function forgotPasswordInvite(email) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${config.apiUrl}/user/forgotPasswordInvite/${email}`, requestOptions).then(
        handleResponse
    );
}

function update(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/user/update`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${config.apiUrl}/user/delete/${id}`, requestOptions).then(handleResponse);
}
async function getCurrentUser() {
    let userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
    // const requestOptions = {
    //     method: 'GET',
    //     headers: authHeader()
    // };

    // return fetch(`${config.apiUrl}/users/${userFromLocalStorage.id}`, requestOptions).then(handleResponse);

    // return new Promise((resolve, reject) => {
    //     resolve(userFromLocalStorage);
    // });
    return userFromLocalStorage;
}
async function isEmailUnique(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };

    return fetch(`${config.apiUrl}/user/isunique`, requestOptions).then(handleResponse);
}

function validateInvite(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
        // body: JSON.stringify(requestObject)
    };
    return fetch(`${config.apiUrl}/user/validateinvite?codeString=${request}`, requestOptions).then(
        handleResponse
    );
}

function validatePasswordReset(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
        // body: JSON.stringify(requestObject)
    };
    return fetch(
        `${config.apiUrl}/user/validatePasswordReset?codeString=${request}`,
        requestOptions
    ).then(handleResponse);
}

function resetPassword(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/user/resetPassword`, requestOptions).then(handleResponse);
}

function sendRegistrationInviteEmail(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
        // body: JSON.stringify(requestObject)
    };
    return fetch(
        `${config.apiUrl}/user/sendRegistrationInviteEmail/${request}`,
        requestOptions
    ).then(handleResponse);
}

function getAllProviders(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/user/getallproviders`, requestOptions).then(handleResponse);
}
function wppOpenLogin(userDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', mode: 'cors' },
        body: JSON.stringify(userDetails)
    };
    return fetch(`${config.apiUrl}/auth/wppOpenLogin`, requestOptions).then(handleResponse);
}

function registerAccountWPPOpen(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };

    return fetch(`${config.apiUrl}/user/registeraccountWPPOpen`, requestOptions).then(handleResponse);
}
