import React from 'react';
import { ThemeProvider } from '../App/Theme/ThemeContext';
import AdminTenantpage from './AdminTenant/AdminTenantPage';
import DefaultTenantpage from './DefaultTenant/DefaultTenantPage';
import { LiveUpdateProvider } from '../App/LiveUpdate/LiveUpdateContext';
import { OsContextProvider } from '../Settings/WPPOpenLogin/OsContextProvider';

const SubdomainRoute = (props) => {
    const subdomain = window.location.hostname.split('.');
    //console.log("subdomain: " + subdomain[0]);
    if (subdomain && subdomain.length > 1 && subdomain[0] == 'admin')
        return (
            <ThemeProvider>
                <AdminTenantpage />
            </ThemeProvider>
        );

    return (
        <ThemeProvider>
            <OsContextProvider>
                <LiveUpdateProvider>
                    {' '}
                    <DefaultTenantpage />
                </LiveUpdateProvider>
            </OsContextProvider>
        </ThemeProvider>
    );
};

export default SubdomainRoute;
