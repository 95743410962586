import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { LOGIN_PROVIDER_LABEL } from '../LoginSettingManage';
import { CheckboxInput } from '../CheckboxInput';
import { DomainConfigurator } from './DomainConfigurator';
import { WPPOpenDomain } from './WPPOpenDomain';
import { store, utilService } from '../../../_helpers';
import { alertActions, tenantService } from '../..';

const initialValidationState = {
    autoUserCreation: {
        validated: true,
        message: ''
    }
};

const DefaultDomainName = 'default';

function WPPOpenLoginConfig({
    showWPPOpenLoginModal,
    setShowWPPOpenLoginModal,
    groups,
    roles,
    canSave,
    fetchData,
    ...props
}) {
    const [enabled, setEnabled] = useState(props.enabled);
    const [autoUserCreationEnabled, setAutoUserCreationEnabled] = useState(
        props.autoUserCreationEnabled.value
    );

    const { dispatch } = store;
    const { loading, success } = alertActions;

    const [domainConfigurations, setDomainConfigurations] = useState(props.domainConfigurations);

    const [selectedDomain, setSelectedDomain] = useState({});
    const [domainConfigMode, setDomainConfigMode] = useState(1);
    const [validations, setValidations] = useState(initialValidationState);

    const onUpdateDomainConfiguration = (domainConfig, mode) => {
        if (mode === 1) {
            let groupNames = domainConfig.groups?.map((group) => group.name).join(', ');
            var newDomain = {
                name: domainConfig.name,
                id: utilService.generate6DigitUUID(),
                role: domainConfig.selectedRole.name,
                roleId: domainConfig.selectedRole.id,
                groups: domainConfig.selectedGroupIds,
                groupNames: groupNames
            };

            setDomainConfigurations((current) => [...current, newDomain]);
        } else {
            setDomainConfigurations((current) => {
                var findIndex = current.findIndex((x) => x.id === domainConfig.id);
                var target = current[findIndex];
                target.name = domainConfig.name;

                if (domainConfig.selectedRole !== undefined) {
                    target.role = domainConfig.selectedRole.name;
                    target.roleId = domainConfig.selectedRole.id;
                }
                if (domainConfig.selectedGroupIds !== undefined) {
                    let groupNames = domainConfig.groups?.map((group) => group.name).join(', ');
                    target.groups = domainConfig.selectedGroupIds;
                    target.groupNames = groupNames;
                }
                current[findIndex] = target;
                return [...current];
            });
        }

        return true;
    };

    const handleUpdateSettings = async (event) => {
        event.preventDefault();
        if (!(await validateSettings())) {
            return;
        }

        var settings = {
            enabled: enabled,
            autoUserCreationEnabled: autoUserCreationEnabled,
            domainConfigurations: domainConfigurations
        };
        onUpdateWPPOpenLoginSettings(settings);
    };
    const onUpdateWPPOpenLoginSettings = async (payload) => {
        dispatch(loading(true));
        await tenantService.updateWPPOpenLoginSettings(props.currentTenant.id, payload);
        dispatch(success('Saved'));
        dispatch(loading(false));
        await fetchData();
        setShowWPPOpenLoginModal(false);
    };

    const onEdit = (domain) => {
        setDomainConfigMode(2);
        setSelectedDomain(domain);
    };

    const handleDomainDelete = (domain) => {
        setDomainConfigurations((prev) => prev.filter((p) => p.id !== domain.id));
    };

    const defaultDomainChange = (checked) => {
        setDomainConfigurations((current) => {
            var findIndex = current.findIndex((x) => x.name.toLowerCase() === 'default');
            current[findIndex].isActive = checked;
            return [...current];
        });
    };

    const isUniqueDomain = (name, id, operationMode) => {
        if (operationMode === 1) {
            let exists = domainConfigurations.some(
                (x) => x.name.toLowerCase() === name.toLowerCase()
            );
            return !exists;
        } else {
            let exists = domainConfigurations.some(
                (x) => x.name.toLowerCase() === name.toLowerCase() && x.id !== id
            );
            return !exists;
        }
    };
    const handleChangeEnabled = (event) => {
        var { checked } = event.target;
        setEnabled(checked);
    };

    const handleChangeAutoUserCreation = (event) => {
        var { checked } = event.target;
        setAutoUserCreationEnabled(checked);
    };

    const validateSettings = async () => {
        var globalValidation = true;
        var validationCopy = { ...validations };

        let defaultDomain = domainConfigurations[0];

        var isDefaultInActive =
            defaultDomain.name.toLowerCase() !== DefaultDomainName ||
            defaultDomain.isActive === false;
        if (
            autoUserCreationEnabled === true &&
            domainConfigurations.length === 1 &&
            isDefaultInActive
        ) {
            validationCopy.autoUserCreation = {
                validated: false,
                message: 'Default domain is inactive'
            };
            globalValidation = false;
        } else {
            validationCopy.autoUserCreation = initialValidationState.name;
        }

        setValidations(validationCopy);

        return globalValidation;
    };

    return (
        <Modal
            show={showWPPOpenLoginModal}
            onHide={async () => {
                await fetchData();
                setShowWPPOpenLoginModal(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}>
            <Modal.Header closeButton={true}>
                <h5>{`Configure ${LOGIN_PROVIDER_LABEL.wppopen} Login`}</h5>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <CheckboxInput
                        header={'Status'}
                        title={'Enabled'}
                        handleChange={handleChangeEnabled}
                        enabled={enabled}></CheckboxInput>

                    <CheckboxInput
                        header={'Auto User Creation'}
                        title={'Enabled'}
                        handleChange={handleChangeAutoUserCreation}
                        validation={validations.autoUserCreation}
                        enabled={autoUserCreationEnabled}></CheckboxInput>

                    <DomainConfigurator
                        onUpdateDomainConfiguration={onUpdateDomainConfiguration}
                        groups={groups}
                        roles={roles}
                        groupObject={selectedDomain}
                        setGroupObject={setSelectedDomain}
                        operationMode={domainConfigMode}
                        {...{
                            domainConfigMode,
                            setDomainConfigMode,
                            isUniqueDomain
                        }}
                    />

                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th className="wpp-domain-cell-action"></th>
                                <th>Domain Name</th>
                                <th>Role</th>
                                <th>Group</th>
                            </tr>
                        </thead>
                        <tbody>
                            {domainConfigurations.map((config, index) => {
                                return (
                                    <WPPOpenDomain
                                        key={index}
                                        config={config}
                                        onEdit={onEdit}
                                        defaultDomainChange={defaultDomainChange}
                                        handleDomainDelete={handleDomainDelete}
                                    />
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <div>
                    <Button
                        className="mrnda-btn btn-sm mr-2"
                        onClick={handleUpdateSettings}
                        style={{ width: 60 }}
                        disabled={!canSave}>
                        {'Save'}
                    </Button>

                    <Button
                        className="btn-sm mrnda-btn--secondary"
                        onClick={async () => {
                            await fetchData();
                            setShowWPPOpenLoginModal(false);
                        }}
                        style={{ minWidth: 60 }}>
                        {'Cancel'}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default WPPOpenLoginConfig;
