import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeChanger } from './Theme/ThemeChanger';
import { colorUtilService, history, store, utilService } from '../../_helpers';
import { AuthorizationComponent, authorizationConstants } from '../_shared';
import { authentication, userActions } from '../User';
import { connect } from 'react-redux';
import HelpCircleIcon from '../_shared/Icon/HelpCircleIcon';
import { useTheme } from './Theme/ThemeContext';
import { clearNavConfig } from '../../_helpers/navbarUtil';


const SettingsLink = ({ classes, isAuthorized }) => {
    return (
        <Link
            to={isAuthorized ? '/settings' : '#'}
            replace={true}
            className={isAuthorized ? classes : classes + ' disabled'}>
            Settings
        </Link>
    );
};

function NavSecondaryActionsHOC(props) {
    
    const {themeColors} = useTheme();
    const  handleLogout = (e) => {
        e.preventDefault();
        store.dispatch(userActions.logout());
        //history.push('/landing');
        var { from } = { from: { pathname: '/' } };
        clearNavConfig();
        history.push({ ...from, hasBackRoute: false, isLogoutReloading: true });
    }
    

    const renderUserArea = (authenticationData) => {
        if (authenticationData && Object.getOwnPropertyNames(authenticationData).length !== 0) {
            return (
                <>
                    {props.alert.isHideRightNavActions !== true && (
                        <>
                            <li style={{}} className="mrnda-secondary-navigation__item">
                                <Link
                                    to="/newsupportrequest"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Brandshare Collaboration"                                    
                                    >
                                    {
                                        props.compactNavbar !== true
                                            ? <p style={{ color: 'white' }} className="text" target="_blank">
                                                <i className="far fa-question-circle mr-1" />
                                                Support
                                            </p>
                                            : <HelpCircleIcon height={22} width={22} color='var(--primary-text-color)' />
                                    }
                                   
                                </Link>
                            </li>                            
                            <li style={{}} className="mrnda-secondary-navigation__item">
                                <ThemeChanger invertedColor={props.compactNavbar} />
                            </li>
                            <li className="mrnda-secondary-navigation__item dropdown">
                                <a
                                    className="main-header__user"
                                    href="javascript:void(0)"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-toggle={
                                        props.alert.isNavActionsDisabled !== true
                                            ? 'dropdown'
                                            : ''
                                    }
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                        
                                    <div className="mrnda-avatar" style={props.compactNavbar === true ? { height: '30px', width: '30px', fontSize: '12px', lineHeight: '30px' , backgroundColor: themeColors? colorUtilService.changeOpacity(themeColors?.primaryTextColor, 0.4) : ''  } : {}} >
                                        {utilService.getUserInitial(authenticationData.fullName)}
                                    </div>{' '}
                                    {/* Should be user initals */}
                                </a>
                                {props.alert.isNavActionsDisabled !== true && (
                                    <div
                                        id="navbarUserDropdown"
                                        className="dropdown-menu mr-4"
                                        aria-labelledby="navbarDropdownMenuLink">
                                        {/* <a className="dropdown-item" href="#">
                            Edit Profile
                        </a> */}                                        
                                        <AuthorizationComponent
                                            permissionKey={{
                                                module: authorizationConstants.Settings.KEY,
                                                action: authorizationConstants.Settings
                                                    .ACTION_SETTINGS_VIEW
                                            }}
                                            component={SettingsLink}
                                            classes="dropdown-item"
                                        />
                                        {
                                            props.authentication.authProvider === 5 ? <></> :
                                                <a className="dropdown-item" onClick={handleLogout}>
                                                    Log Out                                                   
                                                </a>
                                        }
                                        
                                    </div>
                                )}
                                <div className="ml-10" />
                            </li>
                        </>
                    )}
                </>
            );
        } else {
            return (
                <li className="mrnda-secondary-navigation__item dropdown">
                    <a
                        className="main-header__user"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <span className="main-header__user-icon margin-_5x--right">
                            <i className="fas fa-user-alt" />
                        </span>
                    </a>
                    {props.disableLogin !== true &&
                        <div
                            id="navbarUserDropdown"
                            className="dropdown-menu mr-4"
                            aria-labelledby="navbarDropdownMenuLink">

                            <Link to="/landing" className="dropdown-item">
                                Login
                            </Link>
                        </div>}
                    <div className="ml-10" />
                </li>
            );
        }
    }

    return (
        <ul style={props.customStyles} className={`mrnda-secondary-navigation ${props.alert.isHideTopbar ? 'd-none' : ''}`}>            
            {renderUserArea(props.authentication)}
        </ul>
    );
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication: authentication.user ? authentication.user.authData : {}
    };
}
const NavSecondaryActions = connect(mapStateToProps)(NavSecondaryActionsHOC); 
export default NavSecondaryActions;

